/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

const BackToTopButtonComponent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;

            // Define una altura de desplazamiento a partir de la cual mostrar el botón
            if (scrollTop > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        // Agregar el listener de scroll cuando se monta el componente
        window.addEventListener('scroll', handleScroll);

        // Eliminar el listener de scroll cuando se desmonta el componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <a
            href="#"
            className={`back-to-top d-flex align-items-center justify-content-center ${isVisible ? 'active' : ''}`}
            onClick={scrollToTop}
        >
            <i className="bi bi-arrow-up-short"></i>
        </a>
    );
};

export default BackToTopButtonComponent;
