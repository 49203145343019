/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '../../assets/img/logo.webp';
//import React, { useEffect, useState } from 'react';
//import HeroDownload from './assets/img/hero-download.webp';
import Footer from '../../component/FooterComponent';
import Precargar from '../../component/ReloaderComponent';
import { Link } from 'react-router-dom';
import Navbar from '../../component/NavbarComponent';
//import windowsSvg from './assets/img/windows.svg'; // Importa el archivo SVG


//import('../../assets/js/main'); // Importa el archivo main.js

function Policy() {

    // const [currentYear, setCurrentYear] = useState('');

    // useEffect(() => {

    //     const d = new Date();
    //     const year = d.getFullYear();
    //     setCurrentYear(year);
    // }, []); // El segundo parámetro vacío [] asegura que el efecto solo se ejecute una vez, cuando el componente se monta


    return (
        <div className="App">
            <Precargar />

            <body style={{ background: "#181A20" }}>

                <style
                    dangerouslySetInnerHTML={{
                        __html: "\n    li {\n      color: #fff;\n    }\n  "
                    }}
                />
                {/* <!-- ======= Header ======= --{'>'} */}
                <header id="header">
                    <div class="container d-flex align-items-center justify-content-between">
                        {/* <!-- Uncomment below if you prefer to use an image logo --{'>'} */}
                        <Link to="/" className="logo"><img src={Logo} alt="" class="img-fluid" /></Link>
                        <h1 class="logo">
                            <Link to="/" style={{ textDecoration: "none" }}>WikiMedia</Link></h1>

                        <Navbar />

                    </div>
                </header>{/* <!-- End Header --{'>'} */}

                {/* <!-- ======= Breadcrumbs ======= --> */}
                {/* <section class="breadcrumbs" style={{background: "#181A20" }}>
                    <div class="container">

                        <div class="d-flex justify-content-between align-items-center">
                            <h2 style={{color:" #fff" }}>Política de privacidad</h2>
                            <ol>
                                <li><a href="../index.html#hero">Home</a></li>
                                <li>Política de privacidad</li>
                            </ol>
                        </div>

                    </div>
                </section> */}
                {/* <!-- End Breadcrumbs --> */}


                {/* <!-- End Hero --> */}

                <main id="main">

                    <section class="breadcrumbs" style={{ background: "#181A20" }}>
                        <div class="container">

                            <div class="d-flex justify-content-between align-items-center">
                                <h2 style={{ color: " #fff" }}>Política de privacidad</h2>
                                <ol>
                                    <li><a href="/">Home</a></li>
                                    <li>Política de privacidad</li>
                                </ol>
                            </div>

                        </div>
                    </section>

                    {/* <!-- ======= Hero Section ======= --{'>'} */}
                    <section class="inner-page" style={{ background: "#181A20" }}>


                        {/* <!-- Block level --> */}
                        <div class="container">
                            <div class="polictText">
                                <p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        Al instalar la aplicación usted indica que conoce y entiende los siguientes puntos de política de privacidad
                                    </span></p>
                                <p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        1 - A través de esta aplicación no se recopilan datos de carácter personal de los usuarios.
                                    </span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        2 - No se registran direcciones IP.
                                    </span></p>
                                <p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        3 - No se accede a las cuentas de correo de los usuarios.
                                    </span></p>
                                <p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        4 - La aplicación no guarda datos ni hace seguimientos sobre tiempos y horarios de utilización.</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        5 - La aplicación no guarda información relativa a tu dispositivo como, por ejemplo,
                                        fallos, actividad del sistema, ajustes del hardware, tipo de navegador, idioma del navegador.
                                    </span></p>
                                <p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        6 - La aplicación no accede a tus contactos ni agendas.</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        7 - La aplicación no recopila información sobre tu ubicación real.
                                    </span></p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        8 - Remarketing con Google AdMob</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        Proveedores como Google, utilizan cookies de primer nivel y cookies de terceros
                                        u otros identificadores de terceros para compilar datos sobre las interacciones
                                        de los usuarios con las impresiones de anuncios y otras funciones de servicio de anuncios.
                                    </span></p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        9 - Clasificación por edades: PEGI 3 - Apto para todos los públicos.
                                    </span></p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        10 - Phantom Solutions, no Google, es el único responsable de Phantom Solutions y su contenido.</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        11 - Mantenimiento y Soporte: </span>
                                    <span>
                                        Phantom Solutions</span>
                                    <span >
                                        , no Google, estará obligado a proporcionar dicho mantenimiento o soporte.</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        12 - Cargos y cuotas:</span></p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        Cualquier uso de esta aplicación es totalmente gratuito.</span></p>
                                <p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        13 - Cambios en nuestra Política de Privacidad:</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        Nuestra Política de Privacidad puede cambiar de vez en cuando.</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        Publicaremos cualquier cambio de política de privacidad en esta página, por lo que debe revisarla periódicamente.</span>
                                </p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >
                                        14 - Contacto:</span></p><p dir="ltr" class="CDt4Ke zfr3Q">
                                    <span >   Si tiene alguna pregunta sobre esta Política o para informar de cualquier violación de la Política, envíe un correo electrónico a: phantomkna@gmail.com</span></p><p dir="ltr" class="CDt4Ke zfr3Q"><br /></p></div>
                        </div>


                    </section>
                </main >
                {/* <!-- End #main --> sad*/}

                < Footer />
                {/*  < !--End Footer-- > */}


                <a a href="#" class="back-to-top d-flex align-items-center justify-content-center" > <i
                    class="bi bi-arrow-up-short"></i></a>

                {/* <!--Reload Web-- >     */}
                {/* <Preloader /> */}
                {/* <div id="preloader">
                    <img src={Logo} class="img-preloader" />

                </div> */}

                {/* <!-- Template Main JS File --> */}



            </body >

        </div >
    );
}

export default Policy;
