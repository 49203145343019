/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
//import logo from './assets/img/favicon.png';

import Logo from './assets/img/logo.webp';
//import HeaderGB from './assets/img/header-bg.jpg';
import HeeroImg from './assets/img/hero-home.webp';
//import React, { useEffect, useState } from 'react';
import Footer from './component/FooterComponent';
import Precargar from './component/ReloaderComponent';
import Navbar from './component/NavbarComponent';

import { Link } from 'react-router-dom';
//import('./assets/js/main'); // Importa el archivo main.js


function App() {

  // eslint-disable-next-line no-unused-vars
  const olinkgplay = (x) => {
    // eslint-disable-next-line no-restricted-globals
    open("https://play.google.com/store/apps/details?id=com.phantomsolutions.wikimedia");

  }
  // eslint-disable-next-line no-unused-vars
  const olinktele = (x) => {
    // eslint-disable-next-line no-restricted-globals
    open("https://t.me/wiki_media_main");
  }

  const olinkhtml = (x) => {
    // eslint-disable-next-line no-restricted-globals
    open("./download/");
  }

  // const [loaded, setLoaded] = useState(false);

  // // useEffect(() => {
  // //   const handleLoad = () => {
  // //     setLoaded(true);
  // //   };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoaded(true);
  //   }, 100000); // 10 segundos (10000 milisegundos)

  //   // Maneja el evento 'load' para detectar cuando se ha cargado todo el contenido de la página
  //   //  window.addEventListener('load', handleLoad);

  //   // Maneja el evento 'DOMContentLoaded' como una alternativa para detectar cuando se ha cargado todo el DOM
  //   // Si 'load' no se dispara por alguna razón, 'DOMContentLoaded' se encargará de mostrar el contenido después de que el DOM esté listo
  //   //  document.addEventListener('DOMContentLoaded', handleLoad);

  //   // Limpia los event listeners cuando el componente se desmonta
  //   return () => {
  //     //   window.removeEventListener('load', handleLoad);
  //     //   document.removeEventListener('DOMContentLoaded', handleLoad);
  //     return () => clearTimeout(timer); // Limpiar el temporizador al desmontar el componente
  //   };
  // }, []);


  // const [currentYear, setCurrentYear] = useState('');

  // useEffect(() => {

  //   const d = new Date();
  //   const year = d.getFullYear();
  //   setCurrentYear(year);
  // }, []); // El segundo parámetro vacío [] asegura que el efecto solo se ejecute una vez, cuando el componente se monta


  return (
    <div className="App">
      <Precargar />


      {/* <!-- ======= Header ======= --{'>'} */}



      <header id="header" class="fixed-top">
        <div class="container d-flex align-items-center justify-content-between">
          {/* <!-- Uncomment below if you prefer to use an image logo --{'>'} */}
          <Link to="/" className="logo"><img src={Logo} alt="" class="img-fluid" /></Link>
          <h1 class="logo">
            <Link to="/" style={{ textDecoration: "none" }}>WikiMedia</Link></h1>

             <Navbar /> 
              

                        

          {/* <!-- .navbar --{'>'} */}

        </div>
      </header>{/* <!-- End Header --{'>'} */}

      {/* <!-- ======= Hero Section ======= --{'>'} */}
      <section id="hero" class="d-flex align-items-center">

        <div class="container-fluid">
          <div class="row justify-content-center">
            <div
              class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center text-center">
              <h1>Bienvenido a</h1>
              <h1 style={{ color: '#e21221' }}>Wiki Media</h1>
              <h2>Accede a un mundo entero de entretenimiento</h2>
              {/* <div><a href="#about" class="btn-get-started scrollto">Comencemos</a></div> */}
              <div>
                <a id='btnstart' type="button" href="#about">
                  Comencemos
                </a>
              </div>
            </div>
            <div class="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img">
              <img src={HeeroImg} class="img-fluid animated" alt="" />

            </div>
          </div>
        </div>

      </section>
      {/* <!-- End Hero --> */}

      <main id="main">



        {/* <!-- ======= About Section ======= --> */}
        <section id="about" class="about">
          <div class="container">
            <div class="row">
              <div class="icon-demo col-lg-6 order-1 order-lg-2 aos-init aos-animate">
                <a id='clickbtn' onClick={olinkhtml} style={{ fontSize: "10em" }} role="img" aria-label="Google Play - large preview" class="icon-demo mt-4 d-flex align-items-center justify-content-center p-3 py-6">
                  <svg /*onMouseEnter={enter} onMouseLeave={leave}*/ viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg" width="300" height="300" style={{ position: 'absolute' }}>
                    <path id="iconsvg" class="iconsvg" stroke="none" stroke-width="0" fill="#f5f5f5"
                      d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813">
                    </path>
                  </svg>
                  {/* <i id="bxlps" onMouseEnter={enter2} onMouseLeave={leave2} class="bx bxl-play-store" style={{ zIndex: 30, lineHeight: 2 }}></i> */}
                  {/* <svg id="bxlps" onMouseEnter={enter2} onMouseLeave={leave2} style={{ zIndex: 30, lineHeight: 2, fontSize: "100px"}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" fill-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12S17.937 22.75 12 22.75S1.25 17.937 1.25 12m7 5a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75m7.78-6.97l-3.5 3.5a.75.75 0 0 1-1.06 0l-3.5-3.5a.75.75 0 1 1 1.06-1.06l2.22 2.22V6a.75.75 0 0 1 1.5 0v5.19l2.22-2.22a.75.75 0 1 1 1.06 1.06" clip-rule="evenodd"/></svg>
                   */}

                  <i style={{ zIndex: 30, lineHeight: 2, marginBottom: "30px" }}>
                    <svg id="bxlps"/* onMouseEnter={enter2} onMouseLeave={leave2} */ style={{ zIndex: 30, lineHeight: 2, fontSize: "100px" }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                      <path id="bxlpsin" fill="#ffffff" fill-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12S17.937 22.75 12 22.75S1.25 17.937 1.25 12m7 5a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75m7.78-6.97l-3.5 3.5a.75.75 0 0 1-1.06 0l-3.5-3.5a.75.75 0 1 1 1.06-1.06l2.22 2.22V6a.75.75 0 0 1 1.5 0v5.19l2.22-2.22a.75.75 0 1 1 1.06 1.06" clip-rule="evenodd" /></svg>
                  </i>
                </a>
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                {/* <h3 class="row justify-content-center">Descarga ya la App</h3> */}
                <p id="pdown" class="text pagination justify-content-center">
                  Disponible en Google Play Store, dejanos una reseña sobre tu experiencia.
                </p>
                <div class="pagination justify-content-center">
                  <ul>
                    <li><i class="bi bi-check-circle"></i> Totalmente gratuita.</li>
                    <li><i class="bi bi-check-circle"></i> Pelis, Series, Animes, y mucho más.</li>
                    <li><i class="bi bi-check-circle"></i> Servidores de Contenido
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#e21221" class="bi bi-badge-sd-fill mb-1" viewBox="0 0 16 16"
                        style={{ marginLeft: '5px' }}>
                        <path d="M10.338 5.968h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z"></path>
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c1.521 0 2.378-.764 2.378-1.88 0-1.007-.642-1.473-1.613-1.692l-.932-.216c-.527-.114-.821-.351-.821-.712 0-.466.39-.804 1.046-.804.637 0 1.028.33 1.103.76h1.125c-.058-.923-.849-1.692-2.22-1.692-1.322 0-2.24.717-2.24 1.815 0 .91.588 1.446 1.52 1.657l.927.215c.624.145.923.36.923.778 0 .492-.391.83-1.13.83-.707 0-1.155-.342-1.234-.808H2.762c.052.95.79 1.75 2.315 1.75ZM8.307 11h2.19c1.81 0 2.684-1.107 2.684-3.015 0-1.894-.861-2.984-2.685-2.984H8.308V11Z"></path>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#e21221" class="bi bi-badge-hd-fill mb-1" viewBox="0 0 16 16"
                        style={{ marginLeft: '5px' }}>
                        <path d="M10.53 5.968h-.843v4.06h.843c1.117 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04z"></path>
                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm5.396 3.001V11H6.209V8.43H3.687V11H2.5V5.001h1.187v2.44h2.522V5h1.187zM8.5 11V5.001h2.188c1.824 0 2.685 1.09 2.685 2.984C13.373 9.893 12.5 11 10.69 11H8.5z"></path>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#e21221" class="bi bi-badge-4k-fill mb-1" viewBox="0 0 16 16"
                        style={{ marginLeft: '5px' }}>
                        <path d="M3.577 8.9v.03h1.828V5.898h-.062a46.781 46.781 0 0 0-1.766 3.001z"></path>
                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm2.372 3.715.435-.714h1.71v3.93h.733v.957h-.733V11H5.405V9.888H2.5v-.971c.574-1.077 1.225-2.142 1.872-3.202zm7.73-.714h1.306l-2.14 2.584L13.5 11h-1.428l-1.679-2.624-.615.7V11H8.59V5.001h1.187v2.686h.057L12.102 5z"></path>
                      </svg> </li>
                  </ul>
                </div>
                <div>

                  <div class="container text-center mt-3">
                    <div class="pagination justify-content-center">
                      <button id='download' type="button" onClick={olinkhtml}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16"
                          style={{ marginRight: '15px', marginBottom: '2px' }}>
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                        </svg>
                        Descargar
                      </button>
                    </div>
                    {/* 
                    <a type="button" class="btn btn-lg btn-radius btn-block btn-pre">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24"
                                                    style={{ marginRight: "5px", marginBottom: "3px" }}>
                                                    <path fill="currentColor" d="M6.382 3.968A8.962 8.962 0 0 1 12 2c2.125 0 4.078.736 5.618 1.968l1.453-1.453l1.414 1.414l-1.453 1.453A8.962 8.962 0 0 1 21 11v1H3v-1c0-2.125.736-4.078 1.968-5.618L3.515 3.93l1.414-1.414zM3 14h18v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1zm6-5a1 1 0 1 0 0-2a1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2a1 1 0 0 0 0 2"></path>
                                                </svg>
                                                Descargar para Android</a>
                    
                    <div class="row">
                      <div class="col">
                        <button type="button" class="btn btn-outline-danger" onclick="olinkhtml()">Play Store
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16"
                          style={{ marginLeft: '5px', marginBottom: '3px' }}>
                            <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z"></path>
                          </svg>

                        </button>
                      </div>
                      <div class="col">

                        <button type="button" class="btn btn-outline-danger" onclick="olinktele()">Telegram
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google-play" viewBox="0 0 16 16"
                          style={{ marginLeft: '5px', marginBottom: '3px' }}>
                            <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z"></path>
                          </svg>

                        </button>
                        
                        
                      </div>
                    </div> */}

                  </div>


                </div>
              </div>
            </div>

          </div>
        </section>{/*<!-- End About Section --> */}

      </main>
      {/* <!-- End #main --> sad*/}

      <Footer />

      {/*  < !--End Footer-- > */}





      {/* <!--Reload Web-- >     */}
      {/* <Preloader /> */}
      {/* <div id="preloader">
        <img src={Logo} class="img-preloader" />

      </div> */}

      {/* <!-- Template Main JS File --> */}





    </div >
  );
}

export default App;
