

const d = new Date();
const year = d.getFullYear();
const currentYear = year;


function Footer() {
    return (
        <div className="Footer">
            {/* <!-- End #main --> sad*/}

            <footer id="footer">

                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                        </div>
                    </div>
                </div>

                <div class="container" id="container">

                    <div class="copyright-wrap d-md-flex py-4">
                        <div class="me-md-auto text-center text-md-start">
                            <div class="copyright">
                                <strong>&copy; <span>WikiMedia</span> </strong>. All Rights Reserved. Copyright 2022 - {currentYear}
                            </div>
                            <div class="credits">
                                Designed by <a href="https://www.facebook.com/ernesto.gavilaneslara">Ghost_KNA</a>
                            </div>
                        </div>
                        <div class="social-links text-center text-md-right pt-3 pt-md-0">
                            {/* <a href="https://play.google.com/store/apps/details?id=com.phantomsolutions.wikimedia" class="play-store"><i class="bx bxl-play-store"></i></a> */}
                            <a href="https://chat.whatsapp.com/BibdxaZN1M9HCVat7gfKPS" class="whatsapp"><i class="bi bi-whatsapp"></i></a>
                            <a href="https://t.me/wiki_media_main" class="telegram"><i class="bx bxl-telegram"></i></a>

                        </div>
                    </div>

                </div>
            </footer>
            {/*  < !--End Footer-- > */}
        </div>
    );
}

export default Footer;
