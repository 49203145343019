/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '../../assets/img/meta-tags.webp';
//import React, { useEffect, useState } from 'react';
//import HeroDownload from './assets/img/hero-download.webp';
import Footer from '../../component/FooterComponent';
import Precargar from '../../component/ReloaderComponent';
import Navbar from '../../component/NavbarComponent';
import { Link } from 'react-router-dom';
//import windowsSvg from './assets/img/windows.svg'; // Importa el archivo SVG




//import('./assets/js/main'); // Importa el archivo main.js

function TvDetail() {

  // const [currentYear, setCurrentYear] = useState('');

  // useEffect(() => {

  //     const d = new Date();
  //     const year = d.getFullYear();
  //     setCurrentYear(year);
  // }, []); // El segundo parámetro vacío [] asegura que el efecto solo se ejecute una vez, cuando el componente se monta


  return (
    <div className="App">
      <Precargar />
      <body style={{ background: "#181A20" }}>


        {/* <!-- ======= Header ======= --{'>'} */}
        <header id="header">
          <div class="container d-flex align-items-center justify-content-between">
            {/* <!-- Uncomment below if you prefer to use an image logo --{'>'} */}
            <Link to="/" className="logo"><img src={Logo} alt="" class="img-fluid" /></Link>
            <h1 class="logo">
              <Link to="/" style={{ textDecoration: "none" }}>WikiMedia</Link></h1>

              <Navbar /> 

          </div>
        </header>{/* <!-- End Header --{'>'} */}

        {/* <!-- ======= Breadcrumbs ======= --> */}
        {/* <section class="breadcrumbs" style={{background: "#181A20" }}>
                    <div class="container">

                        <div class="d-flex justify-content-between align-items-center">
                            <h2 style={{color:" #fff" }}>Política de privacidad</h2>
                            <ol>
                                <li><a href="../index.html#hero">Home</a></li>
                                <li>Política de privacidad</li>
                            </ol>
                        </div>

                    </div>
                </section> */}
        {/* <!-- End Breadcrumbs --> */}


        {/* <!-- End Hero --> */}

        <main id="main">

          <section class="breadcrumbs" style={{ background: "#181A20" }}>

          </section>

          {/* <!-- ======= Hero Section ======= --{'>'} */}
          <section class="inner-page" style={{ background: "#181A20" }}>


            {/* <!-- Block level --> */}
            <div class="container text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24">
                <path fill="#e21221" d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z" opacity={0}></path>
                <path fill="#e21221" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                  <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite" to="360 12 12" type="rotate"></animateTransform>
                </path>
              </svg>
            </div>


          </section>
        </main >
        {/* <!-- End #main --> sad*/}

        < Footer />
        {/*  < !--End Footer-- > */}


        <a a href="#" class="back-to-top d-flex align-items-center justify-content-center" > <i
          class="bi bi-arrow-up-short"></i></a>

        {/* <!--Reload Web-- >     */}
        {/* <Preloader /> */}
        {/* <div id="preloader">
                    <img src={Logo} class="img-preloader" />

                </div> */}

        {/* <!-- Template Main JS File --> */}



      </body >

    </div >
  );
}

export default TvDetail;
