/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '../../assets/img/logo.webp';
//import React, { useEffect, useState } from 'react';
import HeroDownload from '../../assets/img/hero-download.webp';
import Footer from '../../component/FooterComponent';
import Precargar from '../../component/ReloaderComponent';
import { Link } from 'react-router-dom';
import Navbar from '../../component/NavbarComponent';
import axios from 'axios';
//import windowsSvg from '../../assets/img/windows.svg'; // Importa el archivo SVG





//import('../../assets/js/main'); // Importa el archivo main.js

function DownloadPage() {

   const AndroidActionDownload = async () => {
        try {
          const response = await axios.get('https://api.github.com/repos/Phantom-KNA/WikiMedia/releases');
          const downloadUrl = response.data[0].assets[0].browser_download_url;
          window.open(downloadUrl); // Abre el enlace en una nueva pestaña
        } catch (error) { 
          console.error('Error al descargar el archivo:', error);
        }
      };


    return (
        <div className="App">
            <Precargar />
            <body style={{ background: "#181A20" }}>


                {/* <!-- ======= Header ======= --{'>'} */}
                <header id="header" class="fixed-top">
                    <div class="container d-flex align-items-center justify-content-between">
                        {/* <!-- Uncomment below if you prefer to use an image logo --{'>'} */}
                        <Link to="/" className="logo"><img src={Logo} alt="" class="img-fluid" /></Link>
                        <h1 class="logo">
                        <Link to="/" style={{ textDecoration: "none" }}>WikiMedia</Link></h1>

                        <Navbar />

                    </div>
                </header>{/* <!-- End Header --{'>'} */}

                {/* <!-- ======= Hero Section ======= --{'>'} */}
                <section id="hero" class="d-flex align-items-center">

                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div
                                class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center text-center">
                                <h1>Descarga</h1>
                                <h1 style={{ color: '#e21221' }}>Wiki Media</h1>
                                <h2>Accede a un mundo entero de entretenimiento</h2>
                                {/* <div><a href="#about" class="btn-get-started scrollto">Comencemos</a></div> */}
                                <div>
                                    <a id='btnstart' type="button" href="#about">
                                        Descargar
                                    </a>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img">
                                <img src={HeroDownload} class="img-fluid animated" alt="" />

                            </div>
                        </div>
                    </div>

                </section>
                {/* <!-- End Hero --> */}

                <main id="main">



                    {/* <!-- ======= About Section ======= --> */}
                    <section id="about" class="about" style={{ marginTop: "100px" }}>

                        <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                            <div className="row">
                                {/* Descargar para Android */}
                                <div className="col-md-3 mb-4 justify-content-center align-items-center">
                                    <div id='card' className="card text-center">
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ marginBottom: "8%" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" x="0" y="0" viewBox="0 0 512.001 512.001">
                                                    <path d="m368.323 0h-224.645c-19.128 0-34.69 15.562-34.69 34.69v442.62c0 19.128 15.562 34.69 34.69 34.69h224.646c19.128 0 34.691-15.562 34.691-34.69v-442.62c-.001-19.128-15.563-34.69-34.692-34.69zm18.691 477.31c0 10.306-8.385 18.69-18.691 18.69h-224.645c-10.306 0-18.69-8.384-18.69-18.69v-442.62c0-10.306 8.384-18.69 18.69-18.69h224.646c10.306 0 18.691 8.384 18.691 18.69v442.62zm-96.879-434.278c0 4.418-3.582 8-8 8h-52.269c-4.418 0-8-3.582-8-8s3.582-8 8-8h52.269c4.418 0 8 3.581 8 8zm-34.135 389.304c-14.835 0-26.904 12.069-26.904 26.904s12.069 26.904 26.904 26.904 26.904-12.069 26.904-26.904-12.069-26.904-26.904-26.904zm0 37.808c-6.012 0-10.904-4.892-10.904-10.904 0-6.013 4.892-10.904 10.904-10.904s10.904 4.892 10.904 10.904-4.891 10.904-10.904 10.904z" fill="#ffffff" data-original="#000000" />
                                                </svg>
                                            </h5>
                                            <div className="justify-content-center">
                                                <button id='downloada' type="button" className="btn" onClick={AndroidActionDownload}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24"
                                                        style={{ marginRight: "5px", marginBottom: "3px" }}>
                                                        <path fill="currentColor" d="M6.382 3.968A8.962 8.962 0 0 1 12 2c2.125 0 4.078.736 5.618 1.968l1.453-1.453l1.414 1.414l-1.453 1.453A8.962 8.962 0 0 1 21 11v1H3v-1c0-2.125.736-4.078 1.968-5.618L3.515 3.93l1.414-1.414zM3 14h18v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1zm6-5a1 1 0 1 0 0-2a1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2a1 1 0 0 0 0 2"></path>
                                                    </svg>
                                                    Descargar para Android
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Descargar para Android TV */}
                                <div className="col-md-3 mb-4 justify-content-center align-items-center">
                                    <div id='card' className="card text-center">
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ marginBottom: "8%" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" x="0" y="0" viewBox="0 0 512 512" fill="#fff">
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <path d="m480.303 381.025h-448.604c-6.955 0-12.613-5.952-12.613-13.268v-257.642c0-7.316 5.658-13.268 12.613-13.268h448.604c6.954 0 12.611 5.952 12.611 13.268v257.643c0 7.315-5.657 13.267-12.611 13.267zm-446.217-15h443.828v-254.178h-443.828z" fill="#ffffff" data-original="#000000" />
                                                            </g>
                                                            <g>
                                                                <path d="m480.303 381.025h-448.605c-6.955 0-12.613-5.951-12.613-13.267v-23.947c0-4.143 3.358-7.5 7.5-7.5h458.829c4.143 0 7.5 3.357 7.5 7.5v23.947c0 7.316-5.657 13.267-12.611 13.267zm-446.218-15h443.829v-14.714h-443.829z" fill="#ffffff" data-original="#000000" />
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path d="m76.597 415.153c-1.564 0-3.142-.487-4.49-1.497-3.315-2.483-3.99-7.184-1.507-10.499l25.563-34.128c2.482-3.316 7.183-3.989 10.499-1.507 3.315 2.483 3.99 7.184 1.507 10.499l-25.563 34.128c-1.474 1.967-3.728 3.004-6.009 3.004z" fill="#ffffff" data-original="#000000" />
                                                                </g>
                                                                <g>
                                                                    <path d="m435.404 415.153c-2.282 0-4.535-1.037-6.009-3.004l-25.563-34.128c-2.483-3.315-1.809-8.016 1.507-10.499 3.313-2.482 8.016-1.808 10.499 1.507l25.563 34.128c2.483 3.315 1.809 8.016-1.507 10.499-1.347 1.01-2.925 1.497-4.49 1.497z" fill="#ffffff" data-original="#000000" />
                                                                </g>
                                                            </g>
                                                            <g>
                                                                <g>
                                                                    <path d="m188.164 215.465c-1.815 0-3.634-.654-5.075-1.98-3.049-2.804-3.247-7.548-.443-10.597 18.822-20.464 45.559-32.201 73.354-32.201 27.797 0 54.534 11.737 73.354 32.201 2.804 3.049 2.605 7.793-.443 10.597-3.05 2.804-7.793 2.605-10.598-.444-15.987-17.384-38.7-27.354-62.313-27.354-23.612 0-46.325 9.971-62.314 27.355-1.478 1.608-3.497 2.423-5.522 2.423z" fill="#ffffff" data-original="#000000" />
                                                                </g>
                                                                <g>
                                                                    <path d="m289.779 247.304c-2.047 0-4.087-.833-5.567-2.473-7.193-7.963-17.476-12.53-28.211-12.53s-21.018 4.567-28.212 12.53c-2.777 3.073-7.519 3.313-10.593.538-3.074-2.777-3.314-7.52-.538-10.593 10.033-11.105 24.373-17.475 39.343-17.475 14.971 0 29.31 6.37 39.342 17.475 2.776 3.074 2.536 7.816-.538 10.593-1.435 1.297-3.233 1.935-5.026 1.935z" fill="#ffffff" data-original="#000000" />
                                                                </g>
                                                                <g>
                                                                    <path d="m256 278.899c-1.97 0-3.91-.8-5.3-2.199-1.4-1.391-2.2-3.32-2.2-5.301 0-1.97.8-3.909 2.2-5.3 1.39-1.399 3.33-2.2 5.3-2.2 1.979 0 3.91.801 5.3 2.2 1.4 1.391 2.2 3.33 2.2 5.3 0 1.98-.8 3.91-2.2 5.301-1.39 1.4-3.321 2.199-5.3 2.199z" fill="#ffffff" data-original="#000000" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g></svg>
                                            </h5>
                                            <div className="justify-content-center">
                                                <button id='downloada' type="button" className="btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24"
                                                        style={{ marginRight: "5px", marginBottom: "3px" }}>
                                                        <path fill="currentColor" d="M6.382 3.968A8.962 8.962 0 0 1 12 2c2.125 0 4.078.736 5.618 1.968l1.453-1.453l1.414 1.414l-1.453 1.453A8.962 8.962 0 0 1 21 11v1H3v-1c0-2.125.736-4.078 1.968-5.618L3.515 3.93l1.414-1.414zM3 14h18v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1zm6-5a1 1 0 1 0 0-2a1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2a1 1 0 0 0 0 2"></path>
                                                    </svg>
                                                    Descargar para Android TV
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Descargar para iOS */}
                                <div className="col-md-3 mb-4 justify-content-center align-items-center">
                                    <div id='card-disa' className="card text-center">
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ marginBottom: "8%" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" x="0" y="0" viewBox="0 0 512.001 512.001" fill="#fff">
                                                    <path d="m368.323 0h-224.645c-19.128 0-34.69 15.562-34.69 34.69v442.62c0 19.128 15.562 34.69 34.69 34.69h224.646c19.128 0 34.691-15.562 34.691-34.69v-442.62c-.001-19.128-15.563-34.69-34.692-34.69zm18.691 477.31c0 10.306-8.385 18.69-18.691 18.69h-224.645c-10.306 0-18.69-8.384-18.69-18.69v-442.62c0-10.306 8.384-18.69 18.69-18.69h224.646c10.306 0 18.691 8.384 18.691 18.69v442.62zm-96.879-434.278c0 4.418-3.582 8-8 8h-52.269c-4.418 0-8-3.582-8-8s3.582-8 8-8h52.269c4.418 0 8 3.581 8 8zm-34.135 389.304c-14.835 0-26.904 12.069-26.904 26.904s12.069 26.904 26.904 26.904 26.904-12.069 26.904-26.904-12.069-26.904-26.904-26.904zm0 37.808c-6.012 0-10.904-4.892-10.904-10.904 0-6.013 4.892-10.904 10.904-10.904s10.904 4.892 10.904 10.904-4.891 10.904-10.904 10.904z" fill="#ffffff" data-original="#000000" />
                                                </svg>
                                            </h5>
                                            <div className="justify-content-center">
                                                <button id='downloada' type="button" className="btn" disabled
                                                    style={{ backgroundColor: "#55575e", borderColor: "#55575e", textDecorationColor: "#25282e", color: "#25282e" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" fill="currentColor" class="bi bi-apple" viewBox="0 0 16 16"
                                                        style={{ marginRight: "5px", marginBottom: "2px" }}>
                                                        <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                                        <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                                    </svg>
                                                    Descargar para iOS
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* WINDOWS */}
                                <div className="col-md-3 mb-4 justify-content-center align-items-center">
                                    <div id='card-disa' className="card text-center">
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ marginBottom: "8%" }}>
                                                <svg version="1.1" width="60px" height="60px" x="0" y="0" viewBox="0 0 380.82968 359.56116"><defs
                                                    id="defs42" />
                                                    <g
                                                        id="g36"
                                                        transform="matrix(0.80525475,0,0,1.0138161,-15.366469,-60.184218)"
                                                        style={{ display: "inline", fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><g
                                                            id="g34"
                                                            style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><g
                                                                id="g4"
                                                                style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><path
                                                                    d="M 481.522,413.9513 31.699,381.025 C 24.762557,380.51726 19.086,375.073 19.086,367.757 V 110.115 c 0,-7.316 5.681917,-12.691706 12.613,-13.268 L 481.20359,59.472361 c 6.93009,-0.576211 10.80982,0.998735 10.80982,8.314735 V 405.12326 c 0,7.315 -3.55596,9.33571 -10.49141,8.82804 z M 34.086,366.025 477.4637,399.79726 V 73.571767 L 34.086,111.847 Z"
                                                                    fill="#ffffff"
                                                                    data-original="#000000"
                                                                    id="path2"
                                                                    style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}
                                                                />
                                                            </g>
                                                            <g
                                                                id="g8"
                                                                style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }} /><g
                                                                    id="g18"
                                                                    style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><g
                                                                        id="g12"
                                                                        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><path
                                                                        d="m 741.39101,382.96796 c -1.564,0 -3.142,-0.487 -4.49,-1.497 -3.315,-2.483 -3.99,-7.184 -1.507,-10.499 l 25.563,-34.128 c 2.482,-3.316 7.183,-3.989 10.499,-1.507 3.315,2.483 3.99,7.184 1.507,10.499 l -12.7815,17.064 -12.7815,17.064 c -1.474,1.967 -3.728,3.004 -6.009,3.004 z"
                                                                        fill="#ffffff"
                                                                        data-original="#000000"
                                                                        id="path10"
                                                                        style={{ display: "none", fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}
                                                                    />
                                                                </g>
                                                                <g
                                                                    id="g16"
                                                                    style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }} /></g><g
                                                                        id="g32"
                                                                        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><g
                                                                    id="g22"
                                                                    style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }} /><g
                                                                    id="g26"
                                                                    style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }} /><g
                                                                        id="g30"
                                                                        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}><path
                                                                        d="m 256,263.899 c -170.667,-175.932667 -85.3335,-87.96633 0,0 z"
                                                                        fill="#ffffff"
                                                                        data-original="#000000"
                                                                        id="path28"
                                                                        style={{ display: "inline", fill: "#fff", fillOpacity: 1, strokeWidth: 1.10676 }}
                                                                    />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <g
                                                        id="g1062"
                                                        transform="matrix(0.80100072,3.0135925,-0.59866338,4.032132,370.07536,-410.14787)"
                                                        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 0.445707 }}><path
                                                            d="m -74.776482,240.11002 c -2.365408,-0.11158 -0.168773,1.45243 -3.475786,0.79375 l -34.276472,-45.95882 c 1.40316,-4.12297 1.82809,-4.71682 4.2591,-7.05275 3.70273,-2.8893 3.62369,-2.01422 7.57833,-2.47561 l 34.985123,46.80945 c -2.359451,4.21837 -1.17362,4.02532 -4.489393,6.50861 -1.346842,1.01021 -2.958701,1.45191 -4.580902,1.37537 z"
                                                            fill="#ffffff"
                                                            data-original="#000000"
                                                            id="path1044"
                                                            style={{ display: "inline", fill: "#fff", fillOpacity: 1, strokeWidth: 0.445707 }}
                                                        />
                                                    </g>
                                                    <g
                                                        id="g1062-0"
                                                        transform="matrix(3.9554736,-0.80064875,5.2920959,0.59913429,-583.83656,-26.530233)"
                                                        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 0.389044 }}><path
                                                            d="m -79.070565,239.47999 c -0.800966,-1.06782 -2.060435,-2.84144 -3.534435,-4.80844 l -28.8331,-37.74639 c -1.48936,-1.61138 1.4611,-4.39761 4.7771,-6.88061 3.313,-2.482 4.38532,-3.27773 6.834716,-2.82605 L -70.599,225.67955 c 1.368454,1.82699 2.463714,3.61193 3.308353,4.68596 0.689454,0.8767 -3.326898,4.69849 -4.815353,5.81304 -1.347,1.01 -6.330103,4.14728 -6.964565,3.30144 z"
                                                            fill="#ffffff"
                                                            data-original="#000000"
                                                            id="path1044-8"
                                                            style={{ display: "inline", fill: "#fff", fillOpacity: 1, strokeWidth: 0.389044 }}
                                                        />
                                                    </g>
                                                </svg>
                                            </h5>
                                            <div className="justify-content-center">
                                                <button id='downloada' type="button" className="btn" disabled
                                                    style={{ backgroundColor: "#55575e", borderColor: "#55575e", textDecorationColor: "#25282e", color: "#25282e" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" fill="currentColor" class="bi bi-apple" viewBox="0 0 16 16"
                                                        style={{ marginRight: "5px", marginBottom: "2px" }}>
                                                        <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                                        <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                                                    </svg>
                                                    Descargar para iOS
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div >





                    </section > {/*<!-- End About Section --> */}

                </main >
                {/* <!-- End #main --> sad*/}

                < Footer />
                {/*  < !--End Footer-- > */}


                <a a href="#" class="back-to-top d-flex align-items-center justify-content-center" > <i
                    class="bi bi-arrow-up-short"></i></a>

                {/* <!--Reload Web-- >     */}
                {/* <Preloader /> */}
                {/* <div id="preloader">
                    <img src={Logo} class="img-preloader" />

                </div> */}

                {/* <!-- Template Main JS File --> */}



            </body >

        </div >
    );
}

export default DownloadPage;
