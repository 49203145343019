/* eslint-disable jsx-a11y/alt-text */
// Precargar.js

import React, { useState, useEffect } from 'react';
import Logo from '../assets/img/logo.png';


function Precargar({ children }) {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Establece loaded en true después de 5 segundos si no se ha disparado ningún evento de carga
    const timeoutId = setTimeout(() => {
      setLoaded(true);
    }, 2000); // 5 segundos


    // Maneja el evento 'load' para detectar cuando se ha cargado todo el contenido de la página
    window.addEventListener('load', handleLoad);

    // Maneja el evento 'DOMContentLoaded' como una alternativa para detectar cuando se ha cargado todo el DOM
    document.addEventListener('DOMContentLoaded', handleLoad);

    // Limpia los event listeners y el temporizador cuando el componente se desmonta
    return () => {
      window.removeEventListener('load', handleLoad);
      document.removeEventListener('DOMContentLoaded', handleLoad);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <div className="Precargar">
      {!loaded && (
        <div className="loading-screen">
          <div id="preloader">
            <img src={Logo} class="img-preloader" />

          </div>
        </div>
      )}
      {loaded && children}
    </div>
  );
}

export default Precargar;
