/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '../../assets/img/logo.webp';
//import React, { useEffect, useState } from 'react';
//import HeroDownload from '../../assets/img/hero-download.webp';
import Footer from '../../component/FooterComponent';
import Precargar from '../../component/ReloaderComponent';
//import windowsSvg from '../../assets/img/windows.svg'; // Importa el archivo SVG

// <!-- Template Main CSS File -->
import '../../assets/css/style.css';
// css link
// <!-- Google Fonts -->
import '../../assets/css/fonts.css';
// <!-- Vendor CSS Files -->
import '../../assets/vendor/aos/aos.css';
import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../assets/vendor/boxicons/css/boxicons.min.css';
import '../../assets/vendor/glightbox/css/glightbox.min.css';
import '../../assets/vendor/swiper/swiper-bundle.min.css';


//import('../../assets/js/main'); // Importa el archivo main.js

function Policy() {

  // const [currentYear, setCurrentYear] = useState('');

  // useEffect(() => {

  //     const d = new Date();
  //     const year = d.getFullYear();
  //     setCurrentYear(year);
  // }, []); // El segundo parámetro vacío [] asegura que el efecto solo se ejecute una vez, cuando el componente se monta


  return (
    <div className="App">
      <Precargar />
      <body style={{ background: "#181A20" }}>


        {/* <!-- ======= Header ======= --{'>'} */}
        <header id="header">
          <div class="container d-flex align-items-center justify-content-between">
            {/* <!-- Uncomment below if you prefer to use an image logo --{'>'} */}
            <a href="index.html" class="logo"><img src={Logo} alt="" class="img-fluid" /></a>
            <h1 class="logo">
              <a href="index.html">WikiMedia</a></h1>

            <nav id="navbar" class="navbar">
              <ul>
                <li><a class="nav-link scrollto" href="/">Home</a></li>
                {/* <li><a class="nav-link scrollto" href="/download/">Download</a></li>
                <li><a class="nav-link scrollto active" href="/policy">Policy</a></li> */}
                {/* <!-- <li><a class="nav-link scrollto" href="inner-page.html">inner page</a></li>
                <li><a class="nav-link scrollto" href="#services">Services</a></li>
                <li><a class="nav-link scrollto " href="#portfolio">Portfolio-detalles</a></li>
                <li><a class="nav-link scrollto " href="portfolio-details.html">Portfolio</a></li>
                <li><a class="nav-link scrollto" href="#team">Team</a></li> 
                <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                    <ul>
                        <li><a href="#">Drop Down 1</a></li>
                        <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i
                                    class="bi bi-chevron-right"></i></a>
                            <ul>
                                <li><a href="#">Deep Drop Down 1</a></li>
                                <li><a href="#">Deep Drop Down 2</a></li>
                                <li><a href="#">Deep Drop Down 3</a></li>
                                <li><a href="#">Deep Drop Down 4</a></li>
                                <li><a href="#">Deep Drop Down 5</a></li>
                            </ul>
                        </li>
                        <li><a href="#">Drop Down 2</a></li>
                        <li><a href="#">Drop Down 3</a></li>
                        <li><a href="#">Drop Down 4</a></li>
                    </ul>
                </li> 
                <li><a class="nav-link scrollto" href="#contact">Contact</a></li> --{'>'} */}
                {/* <li><a class="getstarted scrollto" href="#about">Descarga</a></li> */}
              </ul>
              <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>{/* <!-- .navbar --{'>'} */}

          </div>
        </header>{/* <!-- End Header --{'>'} */}

        {/* <!-- ======= Breadcrumbs ======= --> */}
        {/* <section class="breadcrumbs" style={{background: "#181A20" }}>
                    <div class="container">

                        <div class="d-flex justify-content-between align-items-center">
                            <h2 style={{color:" #fff" }}>Política de privacidad</h2>
                            <ol>
                                <li><a href=".../../index.html#hero">Home</a></li>
                                <li>Política de privacidad</li>
                            </ol>
                        </div>

                    </div>
                </section> */}
        {/* <!-- End Breadcrumbs --> */}


        {/* <!-- End Hero --> */}

        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          <section className="breadcrumbs" style={{ background: "#181A20" }}>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#fff" }}>Consulta de Película en TMDB</h2>
                <ol>
                  <li>
                    <a href="../index.html">Home</a>
                  </li>
                  <li>Consulta de Película en TMDB</li>
                </ol>
              </div>
            </div>
          </section>
          {/* End Breadcrumbs */}
          <section
            className="inner-page"
            style={{ background: "#181A20", paddingTop: 0 }}
          >
            <div className="container">
              <p></p>
              <div id="picture-info" />
              <button
                onclick="copiarContenido()"
                className="btn btn-danger"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                copy all
                <i className="bi bi-clipboard" />
              </button>
              <br />
              <div id="movie-info" />
              <p />
            </div>
          </section>
        </main>
        {/* <!-- End #main --> sad*/}

        < Footer />
        {/*  < !--End Footer-- > */}


        <a a href="#" class="back-to-top d-flex align-items-center justify-content-center" > <i
          class="bi bi-arrow-up-short"></i></a>

        {/* <!--Reload Web-- >     */}
        {/* <Preloader /> */}
        {/* <div id="preloader">
                    <img src={Logo} class="img-preloader" />

                </div> */}

        {/* <!-- Template Main JS File --> */}



      </body >

    </div >
  );
}

export default Policy;
