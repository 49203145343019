import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';

import reportWebVitals from './reportWebVitals';

// <!-- Template Main CSS File -->
import './assets/css/style.css';
// <!-- Google Fonts -->
import './assets/css/fonts.css';
// <!-- Vendor CSS Files -->
import './assets/vendor/aos/aos.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/swiper/swiper-bundle.min.css';




import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import Movieurl from './routes/movieurl/MovieUrlPage'; // Supongamos que tienes un componente para mostrar los detalles de una película
import Tvurl from './routes/tvurl/TvUrlPage'; // Supongamos que tienes un componente para mostrar los detalles de una película
import Movie from './routes/movie/movie'
import Download from './routes/download/DownloadPage'
import Policy from './routes/policy/PolicyPage'
import NotFound from './NotFound'

import BackToTopButtonComponent from './component/BackToTopButtonComponent'; // Importa tu componente de botón de "volver arriba"

//import('./assets/js/main'); // Importa el archivo main.js

// const AppRouter = () => (
//   <Router>
//     <Routes>
//       <Route path="/" element={<App />} />
//       <Route path="/movieurl" element={<MovieDetail />} />
//     </Routes>
//   </Router>
// );

// export default AppRouter;

const router = createBrowserRouter(
  [
  //app
  { path: "/", element: <App />, errorElement: <NotFound />,}, //OK
  { path: "/download/", element: <Download />, errorElement: <NotFound />,}, //OK
  { path: "/policy/", element: <Policy />, errorElement: <NotFound />,}, //OK
  { path: "/movie/", element: <Movie />, errorElement: <NotFound />,},
  { path: "/movieurl/", element: <Movieurl />, errorElement: <NotFound />,},
  { path: "/tvurl/", element: <Tvurl />, errorElement: <NotFound />,},
  //404
  { path: "*", element: <NotFound />, errorElement: <NotFound />,},
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BackToTopButtonComponent />
    <RouterProvider router={router} />
  </React.StrictMode>
);




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Router>
//     <Routes>
//       <Route path="/" element={<App />} />
//       <Route path="/download" element={<Download />} />
//       <Route path="/policy/" element={<Policy />} />
//       <Route path="/movie" element={<Movie />} />
//       <Route path="/movieurl" element={<MovieDetail />} />
//       <Route path="*" errorElement={<ErrorBoundary />} />
//      {/* // <Route path=»*» element={<Navigate to=»/404″ />} /> */}
//     </Routes>
//   </Router>
//   </React.StrictMode>
// );

// function ErrorBoundary() {
//   let error = useRouteError();
//   console.error(error);
//   // Uncaught ReferenceError: path is not defined
//   return <div>Dang!</div>;
// }




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
