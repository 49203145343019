import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  useEffect(() => {

    const headerScroll = () => {
      const header = document.getElementById('header');
      if (header) {
        const headerScrolled = () => {
          if (window.scrollY > 100) {
            header.classList.add('header-scrolled');
          } else {
            header.classList.remove('header-scrolled');
          }
        };
        window.addEventListener('load', headerScrolled);
        window.addEventListener('scroll', headerScrolled); // Cambiado 
        // Retornar una función de limpieza para eliminar el listener cuando el componente se desmonte
        return () => {
          window.removeEventListener('load', headerScrolled);
          window.removeEventListener('scroll', headerScrolled);
        };
      }
    };

    headerScroll();

    const handleMobileNavToggle = (e) => {
      const navbar = document.getElementById('navbar');
      const mobileNavToggle = e.currentTarget;
      navbar.classList.toggle('navbar-mobile');
      mobileNavToggle.classList.toggle('bi-list');
      mobileNavToggle.classList.toggle('bi-x');
    };

    const handleDropdownActivate = (e) => {
      const navbar = document.getElementById('navbar');
      const dropdownToggle = e.currentTarget;
      if (navbar.classList.contains('navbar-mobile')) {
        e.preventDefault();
        const nextSibling = dropdownToggle.nextElementSibling;
        if (nextSibling) {
          nextSibling.classList.toggle('dropdown-active');
        }
      }
    };


    document.querySelectorAll('.mobile-nav-toggle').forEach(item => {
      item.addEventListener('click', handleMobileNavToggle);
      item.addEventListener('click', handleDropdownActivate, true);
    });

    document.querySelectorAll('.navbar .dropdown > Link').forEach(item => {
      item.addEventListener('click', handleDropdownActivate, true);
    });

    return () => {
      document.querySelectorAll('.mobile-nav-toggle').forEach(item => {
        item.removeEventListener('click', handleMobileNavToggle);
      });

      document.querySelectorAll('.navbar .dropdown > Link').forEach(item => {
        item.removeEventListener('click', handleDropdownActivate, true);
      });
    };
  }, []);

  return (
    <nav id="navbar" className="navbar">
      <ul>
        {location.pathname === '/download/' ? (
          <>
            <li><Link to="/" className={`nav-link scrollto ${location.pathname === '/' ? 'active' : ''}`}>Home</Link></li>
            <li><a className="nav-link scrollto" href="#hero">Features</a></li>
            <li><a className="nav-link scrollto active" href="#about">Download</a></li>
            <li><Link to="/policy/" className={`nav-link scrollto ${location.pathname === '/policy/' ? 'active' : ''}`}>Policy</Link></li>
          </>
        ) : (
          <>
          <li><Link to="/" className={`nav-link scrollto ${location.pathname === '/' ? 'active' : ''}`}>Home</Link></li>
          <li><Link to="/download/" className={`nav-link scrollto ${location.pathname === '/download/' ? 'active' : ''}`}>Download</Link></li>
          <li><Link to="/policy/" className={`nav-link scrollto ${location.pathname === '/policy/' ? 'active' : ''}`}>Policy</Link></li>
          </>    
        )}
      </ul>
      <i className="bi mobile-nav-toggle bi-list"></i>
    </nav>
  );
};

export default Navbar;
